import React from 'react'
import '../styles/EditSession.css'
import '../styles/formPage.css'
import formatDate from './formatDate'
function EditSession(props) {

  return (
    <div className={props.isActive ? 'edit-session-container form-page active' : 'edit-session-container form-page'}>
        <div className='info'>
            <h2>جلسة المراجع {props.session.patient}</h2>
        </div>
        <div className='form'>
            <form className='form'>
                <div className='row' style={{gridTemplateColumns:"repeat(2, 1fr)", display:'grid', gap:".5rem"}}>
                    <div className='field-container'>
                        <input name='date' type='date' onChange={e => {props.changeDate('start', e.target.value)}} value={formatDate(props.session.start).replace(/\//g, '-')} className='field' required />
                        <label htmlFor='date' className='active'>تاريخ الجلسة</label>
                    </div>
                    <div className='field-container'>
                        <input name='time' value={props.session.startHour} onChange={e => {props.changeDate('startHour', e.target.value)}} type='time' className='field' required />
                        <label htmlFor='time' className='active'>وقت الجلسة</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='field-container'>
                        <input name='date' type='number' onChange={e => {props.changeDate('paid', e.target.value)}} value={props.session.paid} className='field' required />
                        <label htmlFor='date' className='active'>المبلغ المدفوع</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='field-container'>
                        <input name='date' type='text' onChange={e => {props.changeDate('notes', e.target.value)}} value={props.session.notes} className='field' required />
                        <label htmlFor='date' className='active'>ملاحظات</label>
                    </div>
                </div>
                <div className='row'>
                    <button onClick={(e) => {
                        e.preventDefault()
                        props.apply()
                    }}>تعديل</button>
                </div>
                <div className='row'>
                    <button style={{background:"var(--bg-darker) !important", color:"#ee3b3b"}} onClick={e => {
                        e.preventDefault()
                        props.cancel()
                    }}>الغاء</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default EditSession