import React, { useEffect, useState } from 'react'
import '../styles/formPage.css'
import { url } from '../URL'
import axios from 'axios'
import { BsTrash } from 'react-icons/bs'
import formatDate from './formatDate'
import getTime from './getTime'
import html2canvas from 'html2canvas'
import RecieptAlert from './RecieptAlert'
import '../styles/Sessions.css'
import toIraqiCurrencyFormat from './ToIraqiCurrency'
import convertNumberToWords from './NumToText'
import { useParams, useSearchParams } from 'react-router-dom'


function getCurrentTime12HourFormat() {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    
    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
    
    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    
    // Format minutes as two digits
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Return the formatted time
    return `${hours}:${formattedMinutes} ${period}`;
}
function AddSession() {
    const [data, setData] = useState({
        patient:"",
        service:"",
        category:"",
        publisher:"",
        cost:0,
        start:'',
        startHour:"",
        responsible:"",
        responsibleID:"",
        end:"",
        number:1,
        discount:0,
        paid:0,
        remaining:0,
        notes:"",
        totalDiscount:0
    })
   
    const [suggestion, setSuggestion] = useState("")
    const [patientID, setPatientID] = useState('')
    const [responsibleID, setResponsibleID] = useState('')
    // const [publisherID, setPublisherID] = useState('')
    const [msg, setMsg] = useState('')
    const [dropdowns, setDropdowns] = useState({
        service:false,
        category:false,
        wavelength:false,
        responsible:false
    })
    const [categories, setCategories] = useState([])
    const [patients, setPatients] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [services, setServices] = useState([])
    function openDropdown(dropdown){
        setDropdowns({
            service: dropdown === 'service' ? true : false,
            category: dropdown === 'category' ? true : false,
            wavelength: dropdown === 'wavelength' ? true : false,
            responsible: dropdown === 'responsible' ? true : false,
        })
    }
    const [isLoading, setIsLoading] = useState(true)
    const [sessionServices,setSessionServices] = useState([])
    const [laserData, setLaserData] = useState({
        wavelength:"",
        lensNumber:0,
        machineName:'',
        pulseDuration:0,
        count:0,
        fluenceNumber:0,
        notes:""
    })
    const [selectedService, setSelectedService] = useState(0)
    const [foundSession, setFoundSession] = useState(0)
    const [totalPrice,setTotalPrice] = useState(0)
    function calculateTotalCost(services) {
        return services.map((service) => {
          const totalCost = service.price * service.sessions - service.discount;
          return { ...service, totalCost };
        });
      }
      function sumTotalCost(services) {
        return services.reduce((sum, service) => sum + service.totalCost, 0);
      }
    useEffect(() => {
        if(sessionServices.length === 1){
            setSelectedService(sessionServices[0])
        }
        const withSumData = calculateTotalCost(sessionServices)
        const totalSum = sumTotalCost(withSumData)
        setTotalPrice(totalSum)
    }, [sessionServices])
 
    const [employees, setEmployees] = useState([])
    useEffect(() => {
        setFoundSession({...sessionServices.find(d => d._id === selectedService._id)})
    }, [sessionServices, selectedService])
    async function loadData(){
        await axios.get(`${url}/sessions/patients/`, {withCredentials:true})
                        .then(res => {
                            setPatients(res.data.data)
                        })
                        .catch(err => {
                        alert('حدث خطا')
                            console.log(err.response.data.message)
                        })
        await axios.get(`${url}/sessions/category/`, {withCredentials:true})
                    .then(res => {
                        setCategories(res.data.data)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                        alert('حدث خطا')
                    })
        await axios.get(`${url}/sessions/employees/`, {withCredentials:true})
                    .then(res => {
                        setIsLoading(false)
                        setEmployees(res.data.data)
                    })
                    .catch(err => {
                        setIsLoading(false)
                        console.log(err.response.data.message)
                        alert('حدث خطا')
                    })
    }
    useEffect(() => {
        if(isLoading){
            loadData()
        }
    }, [isLoading])
    useEffect(() => {
        if(data.patient){
            var p = patients.find(d => {return d.name.includes(data.patient)})
            if(p){
                setSuggestion(p)
            }else {
                setSuggestion('')
            }
        }
        window.addEventListener('keyup', (e) => {
            if(e.key === 'ArrowLeft' && suggestion){
                setData((d) => {return {...d, patient:suggestion.name, phone:suggestion.phone}})
                setPatientID(suggestion._id)
            }
        })
    }, [data.patient,suggestion, patients])
    function convertDataToString(data) {
        return data
            .map(item => `${item.category} ${item.name}: ${item.sessions} جلسات`)
            .join(', ');
    }
    const [_isCreatingReceipt, setIsCreatingReceipt] = useState(false)
    const [_afterNotesActivity, setAfterNotesActivity] = useState(false)
    const [receiptUrl, _setReceiptUrl] = useState("")
    const [isReceiptActive, setIsReceiptActive] = useState(false)
    const [areSessionBeingAdded, setAreSessingBeingAdded] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [searchParams] = useSearchParams();
        useEffect(() => {
            const patient = searchParams.get('patient')
            const patientID = searchParams.get('id')
            const phone = searchParams.get('number')
            setData(d => {
                return {...d, patient, phone}
            })
            setPatientID(patientID)
        }, [])
    const generateRec = async (sessionData) => {
        const element = document.getElementById("receiptsPaper"); // Replace with your div's ID
        
        const relation = sessionData.map(d => {return d._id}).join("_")
        const canvas = await html2canvas(element);
        const dataUrl = canvas.toDataURL("image/png"); 
        const d = {...data, service:receiptProcessedData}
        const totalPaid = sessionData.reduce((sum, item) => sum + item.paid, 0)
        const requestData = {...d, image:dataUrl, sessionID:sessionData[0]._id, relation,paid:totalPaid}
        // const date = formatDate(Date.now())
        setIsCreatingReceipt(true)
        const startHour = getCurrentTime12HourFormat()
        try {
          await axios.post(`${url}/sessions/generate-receipt`, {data:{...requestData,phone:data.phone, responsibleID, start:Date.now(),startHour}}, {
                withCredentials:true,
                // headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                if(res.status === 201){
                  setIsCreatingReceipt(false)
                  setImageURL(res.data.imagePath)
                  alert("تم حفظ الوصل بنجاح")
                }
              })
              .catch(err => {
                alert('حدث خطا')
                console.log(err)
              })
            ;
        } catch (error) {
            console.error('Error generating receipt:', error);
        }
      };
    
      async function createSession() {
        setAreSessingBeingAdded(true)
        if (!data.patient || sessionServices.length === 0) {
            setAreSessingBeingAdded(false)
            setMsg("أرجوك قم بملء جميع الحقول");
            return;
        }
    
        try {
            // Validate each service input before sending requests
            for (let i = 0; i < sessionServices.length; i++) {
                const service = sessionServices[i];
                if (!service.name || !service.sessions || !service.start || !service.startHour || !service.paymentDate) {
                    setMsg(`الرجاء التحقق من الحقول للجلسة رقم ${i + 1}`);
                    setAreSessingBeingAdded(false)
                    alert(`الجلسة رقم ${i + 1} تحتوي على حقول غير مكتملة.`);
                    return;
                }
            }
    
            // Prepare service data for API requests
            const serviceRequests = sessionServices.map((service, index) => {
                const remaining = getRemaining(
                    service.price,
                    (service.discount || 0) + (slices[index] || 0),
                    service.paid,
                    service.sessions
                );
                const serviceData = {
                    ...laserData,
                    patient: data.patient,
                    patientID,
                    category: service.category,
                    service: service.name,
                    number: service.sessions,
                    responsibleID,
                    responsible: data.responsible,
                    cost: service.price,
                    discount: (service.discount || 0),
                    paid: service.paid || 0,
                    remaining,
                    notes: service.notes,
                    end: "",
                    start: service.start,
                    startHour: service.startHour,
                    paymentDate: service.paymentDate,
                };
    
                // Handle individual API requests
                return axios.post(`${url}/sessions/create`, { data: serviceData }, { withCredentials: true })
                    .then((response) => {
                        console.log(`Service created successfully: ${service.name}`, response.data.data);
                        removeService(serviceData); // Remove the service locally
                        return { success: true, service: service.name };
                    })
                    .catch((error) => {
                        console.error(`Error creating session for service: ${service.name}`, error);
                        return { success: false, service: service.name, error };
                    });
            });
    
            // Wait for all requests to complete
            const results = await Promise.all(serviceRequests);
    
            // Check for failed requests
            const failedRequests = results.filter(result => !result.success);
            if (failedRequests.length > 0) {
                console.error("Failed requests:", failedRequests);
                alert(`تعذر إنشاء الجلسات التالية: ${failedRequests.map(req => req.service).join(', ')}`);
                setAreSessingBeingAdded(false)
                setMsg("حدث خطأ أثناء محاولة إضافة بعض الجلسات");
                return;
            }
    
            // Generate the receipt
            const receiptGenerated = generateRec(sessionServices);
            if (!receiptGenerated) {
                alert("حدث خطأ أثناء إنشاء الإيصال");
                setAreSessingBeingAdded(false)
                throw new Error("Failed to generate receipt");
            }
    
            setIsReceiptActive(true);
            setMsg("تم أضافة الجلسات بنجاح");
            setAreSessingBeingAdded(false)
        } catch (err) {
            setMsg("أرجوك تأكد أن جميع الحقول صحيحة");
            console.error(err);
            alert("حدث خطأ");
        }
    }
    
    
    
    // async function createSession() {
    //     // if(Boolean( sessionServices.some(d => d.paid !== 0) && data.patient !== '')){
    //         const serviceRequests = sessionServices.map((service,index) => {
    //             const remaining = getRemaining(service.price, (service.discount || 0) +  (slices[index] || 0), service.paid, service.sessions);
    //             const serviceData = {
    //                 ...laserData,
    //                 patient: data.patient,
    //                 patientID,
    //                 category: service.category,
    //                 service: service.name,
    //                 number: service.sessions,
    //                 responsibleID,
    //                 responsible: data.responsible,
    //                 cost: service.price,
    //                 discount: service.discount +  slices[index] || 0,
    //                 paid: service.paid || 0,
    //                 remaining,
    //                 notes: service.notes,
    //                 end: "",
    //                 start: service.start,
    //                 startHour: service.startHour,
    //             };
    
    //             // Send a POST request for this service
    //             console.log(serviceData)
    //             // return axios.post(`${url}/sessions/create`, { data: serviceData }, { withCredentials: true });
    //         });  
    // }
    
    async function updateServices(category){
        await axios.get(`${url}/sessions/services/get-services?category=${category}`, {withCredentials:true})
                    .then(res => {
                        setServices(res.data.data)
                    })
                    .catch(err => {
                        alert('حدث خطا')
                        console.log(err.response.data.message)
                    })
    }


    function getDiscount(price, discount, number){
        return (price * number) - discount
    }

    function getRemaining(price, discount, paid, number){
        const totalPrice = price * number 
        const finalDiscount = discount
        const priceAfterDiscount = totalPrice - finalDiscount
        const remaining = priceAfterDiscount - paid 
        return remaining
    }
  

    const [receiptProcessedData,setReceiptProcessedData] = useState('')
    useEffect(() => {
        setReceiptProcessedData(convertDataToString(sessionServices))
    }, [sessionServices])
    useEffect(() => {
        updateServices(categoryId)
        if(!data.category.includes('ليزر')){
            setLaserData({
                wavelength:"",
                lensNumber:0,
                machineName:'',
                pulseDuration:0,
                count:0,
                fluenceNumber:0
            })
        }
    }, [categoryId, data.category])

    function updateServiceField(serviceId, key, value) {
        setSessionServices((prevServices) =>
            prevServices.map((service) =>
                service._id === serviceId
                    ? { ...service, [key]: value }
                    : service
            )
        );
    }
    const [slices, setSlices] = useState([])
    function changeTotalDiscount() {
        let remainingDiscount = data.totalDiscount; // Start with the total discount to be distributed
        const numOfServices = sessionServices.length
        const slice = remainingDiscount/numOfServices
        const arr = [...Array(numOfServices)]
        const slicesArr = arr.map(() => {
            return toIraqiCurrencyFormat(slice)
        })
        
        setSlices(slicesArr)
      }
      useEffect(() => {
        // console.log(slices)
       if(totalPrice <= data.totalDiscount){
        const allPrices = sessionServices.map(d => {return d.price * d.sessions - d.discount})
        setSlices(allPrices)
       }else {
        sessionServices.map((d, i) => {
            const sessionPriceAfterDiscount = d.price * d.sessions - d.discount - slices[i]
            if(sessionPriceAfterDiscount < 0){
                const absoluteDifference = Math.abs(sessionPriceAfterDiscount)
                setSlices(prevSlices =>
                    prevSlices.map((slice, index) => {
                      if (index === i) {
                        return slice - absoluteDifference;
                        // return slice;
                      }
                      return slice + absoluteDifference; 
                    //   return slice; 
                    })
                  );
            }
        })
       }
      }, [slices])
      
    useEffect(() => {
        if (data.totalDiscount !== undefined && sessionServices.length > 0) {
          changeTotalDiscount();
        }
      }, [data.totalDiscount, sessionServices.length]); // Dependencies: reactively run this when these values change
    
    function addNewService(service){
        if(!sessionServices.find(d => d._id === service._id)){
            setData((d => {return {...d, service: service.name, cost:service.price}}))
            setSessionServices(prev => {
                return [...prev, {...service, category:data.category, sessions:1, discount:0, paid:0, start:"", startHour:"", notes:""}]
            })
        }
        openDropdown('')
    }

    function removeService(service){
        const id = service._id 
        setSessionServices((prevItems) => prevItems.filter((item) => item._id !== id))
        setSelectedService(sessionServices[0])
    }
    return (
    <div className='form-page add-session page-container'>
        <RecieptAlert hasAfterNotes={true} addNotes={() => {
            setAfterNotesActivity(true)
        }} url={receiptUrl} data={{...data,
         service:receiptProcessedData,
         paid:sessionServices.reduce((sum, item) => sum + item.paid, 0) || 0,
         image:imageURL
         }} active={isReceiptActive} />
        <div className={isReceiptActive ? 'shadow active' : "shadow"} onClick={() => {
            setIsReceiptActive(false)
            setTimeout(() => {
                window.location.reload()
            }, 750);
        }}
            ></div>
        <section className='head-section' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div>
                <h1 className='title'>إضافة جلسات</h1>
            </div>
        </section>
        <div className='form'>
            <form>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                    <div className='field-container disabled'>
                        <input disabled className={data.patient === '' ? 'field' : 'field active'} type='text' name='name' value={data.patient} onChange={(e) => {setData(d => {return {...d, patient:e.target.value}})}}  />
                        <label className={data.patient === '' ? '' : 'active'} htmlFor='name'>الأسم</label>
                        <p className={suggestion && data.patient !== suggestion && data.patient !== '' ? 'suggessions active' : 'suggessions'}>{suggestion.name}</p>
                    </div>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>المسؤول عن الجلسة</label>
                        <div className='category'  onClick={() => {
                        openDropdown('responsible')
                        }}
                        >{data.responsible || "غير محدد"}</div>
                        <div  className={dropdowns.responsible ? 'dropdown active' : 'dropdown'}>
                        <ul>
                            {
                                employees ? employees.map((employee, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d) => {return {...d, responsible:employee.name}})
                                            // setCategoryId(category._id)
                                            setResponsibleID(employee._id)
                                            setData(d => {return {...d, responsible:employee.name}})
                                            openDropdown('')
                                        }}>{employee.name}</li>
                                    )
                                }) : "خطأ"
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='field-container'>
                        <input className={data.phone === '' ? 'field' : 'field active'} type='text' name='name' value={data.phone} onChange={(e) => {setData(d => {return {...d, phone:e.target.value}})}}  />
                        <label className={data.phone === '' ? '' : 'active'} htmlFor='name'>رقم الهاتف</label>
                    </div>
                </div>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>الفئة</label>
                        <div className='category'  onClick={() => {
                        openDropdown('category')
                        }}
                        >{data.category || "غير محدد"}</div>
                        <div  className={dropdowns.category ? 'dropdown active' : 'dropdown'}>
                        <ul>
                            {
                                categories.map((category, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d) => {return {...d, category:category.name, service:''}})
                                            setCategoryId(category._id)
                                            openDropdown('')
                                        }}>{category.name}</li>
                                    )
                                }) 
                            }
                        </ul>
                        </div>
                    </div>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>الخدمة</label>
                        <div className='service'  onClick={() => {
                        openDropdown('service')
                        }}
                    >{data.service || "غير محدد"}</div>
                        <div  className={dropdowns.service ? 'dropdown active' : "dropdown"}>
                        <ul>
                            {
                                services ? services.map((service, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            addNewService(service)
                                        }}>{service.name}</li>
                                    )
                                }) : ''
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                {
                    sessionServices.length > 0 ? (
                        <>
                        <h2 style={{marginTop:'1rem'}}>الخدمة المحددة: {selectedService?.category + ' ' + selectedService?.name}</h2>
                         <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                            <div className='field-container'>
                                    <input className={selectedService?.start === '' ? 'field' : 'field active'} type='date' name='start' value={foundSession?.start} onChange={(e) => updateServiceField(selectedService._id, 'start', e.target.value)}  />
                                    <label className={selectedService?.start === '' ? '' : 'active'} htmlFor='name'>تاريخ الجلسة</label>
                                </div>
                                <div className='field-container'>
                                    <input className={selectedService?.startHour === '' ? 'field' : 'field active'} type='time' name='startHour' value={foundSession?.startHour} onChange={(e) => updateServiceField(selectedService._id, 'startHour', e.target.value)} />
                                    <label className={selectedService?.startHour === '' ? '' : 'active'} htmlFor='name'>وقت الجلسة</label>
                                </div>
                            </div>
                        <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(3, 1fr)", gap:".5rem"}}>
                        <div className='field-container'>
                            <input className={data.number === '' ? 'field' : 'field active'} type='number' min={1} step={1} name='number' value={foundSession?.sessions} onChange={(e) => updateServiceField(selectedService._id, 'sessions', parseFloat(e.target.value))} />
                            <label className={data.number === '' ? '' : 'active'} htmlFor='number'>عدد الجلسات</label>
                        </div>
                        <div className='field-container'>
                            <input className={data.cost === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={foundSession?.price} onChange={(e) => updateServiceField(selectedService._id, 'price', parseFloat(e.target.value))} />
                            <label className={data.cost === '' ? '' : 'active'} htmlFor='cost'>سعر الجلسة ,<span>{convertNumberToWords(foundSession?.price)}</span></label>
                        </div>
                        <div className='field-container'>
                            <input className={data.discount === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={foundSession?.discount} onChange={(e) => updateServiceField(selectedService._id, 'discount', parseFloat(e.target.value))}  />
                            <label className={data.discount === '' ? '' : 'active'} htmlFor='cost'>خصم الخدمة, <span>{convertNumberToWords(foundSession?.discount)}</span></label>
                        </div>
                        <div className='field-container disabled'>
                            <input className='field active' disabled type='number' min={0} step={1000} name='cost' value={getDiscount(
                                foundSession?.price,
                                foundSession?.discount, 
                                foundSession?.sessions) || "nothing"} />
                            <label className='active' htmlFor='cost'>,المبلغ بعد الخصم <span>{convertNumberToWords(getDiscount(
                                foundSession?.price,
                                foundSession?.discount, 
                                foundSession?.sessions) || 0)}</span></label>
                        </div>
                        <div className={selectedService.paid === 0 ? 'field-container red' : 'field-container'}>
                            <input className={data.paid === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={foundSession?.paid} onChange={(e) => updateServiceField(
                            selectedService._id,
                            'paid',
                            parseFloat(e.target.value) > 
                                (foundSession?.price * foundSession?.sessions - foundSession?.discount) 
                                ? (foundSession?.price * foundSession?.sessions - foundSession?.discount) 
                                : parseFloat(e.target.value)
                            )}  />
                            <label className={data.paid === '' ? '' : 'active'} htmlFor='cost'>المدفوع*, <span>{convertNumberToWords(foundSession?.paid)}</span></label>
                        </div>
                        <div className='field-container'>
                            <input className={selectedService?.paymentDate === '' ? 'field' : 'field active'} type='date' name='paymentDate' value={foundSession?.paymentDate || new Date(Date.now())} onChange={(e) => updateServiceField(selectedService._id, 'paymentDate', e.target.value)}  />
                            <label className={selectedService?.paymentDate === '' ? '' : 'active'} htmlFor='name'>تاريخ الدفع</label>
                        </div>
                        <div className='field-container disabled'>
                            <input className='field active' disabled type='number' min={0} step={1000} name='remaining' value={(foundSession?.price * foundSession?.sessions - foundSession?.discount - foundSession?.paid)}  />
                            <label className='active' htmlFor='remaining'>المتبقي, <span>{convertNumberToWords((foundSession?.price * foundSession?.sessions - foundSession?.discount - foundSession?.paid))}</span></label>
                        </div>
                    </div>
                     <div className='row'>
                    <div className='field-container'>
                        <input className='field active' type='text' name='notes' value={foundSession?.notes} onChange={(e) => updateServiceField(selectedService._id, 'notes', e.target.value)}  />
                        <label className='active' htmlFor='notes'>ملاحظات</label>
                    </div>
                </div>
                <div className='services-info'>
                    <table>
                        <thead>
                            <tr>
                                <th>أسم الفئة</th>
                                <th>أسم الخدمة</th>
                                <th>التاريخ</th>
                                <th>الوقت</th>
                                <th>عدد الجلسات</th>
                                <th>سعر الجلسة</th>
                                <th>الخصم</th>
                                <th>المجموع</th>
                                <th>المدفوع</th>
                                <th>المتبقي</th>
                                <th>حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            sessionServices.map((service, index) => {
                                return (
                                    <tr key={index} onClick={() => {setSelectedService({...service, discount:service.discount + slices[index]})}} className={selectedService._id === service._id ? "selected" : ""}>
                                    <td>
                                        {service.category}
                                    </td>
                                    <td>
                                        {service.name}
                                    </td>
                                    <td>
                                        {service.start ? formatDate(service.start) : "غير محدد"}
                                    </td>
                                    <td>
                                        {service.startHour ? getTime(service.startHour) : "غير محدد"}
                                    </td>
                                    <td>
                                        {service.sessions}
                                    </td>
                                    <td>
                                        {toIraqiCurrencyFormat(service.price) || 0}
                                    </td>
                                    <td>
                                        {toIraqiCurrencyFormat(service.discount) || 0}
                                    </td>
                                    <td>
                                        {(() => {
                                            return toIraqiCurrencyFormat(service.sessions * service.price - (service.discount) || 0)
                                        })()}
                                    </td>
                                    <td>
                                        {toIraqiCurrencyFormat(service.paid) || 0}
                                    </td>
                                    <td>
                                        {toIraqiCurrencyFormat(service.price * service.sessions - service.discount - service.paid)}
                                    </td>
                                    <td>
                                        <div onClick={() => {removeService(service)}} className='delete-btn-icon'>
                                            <BsTrash />
                                        </div>
                                    </td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {/* <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(3,1fr)", gap:".5rem"}}>
                    <div className='field-container disabled'>
                        <input disabled className={data.totalDiscount === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='totalDiscount' value={totalPrice}/>
                        <label className={data.totalDiscount === '' ? '' : 'active'} htmlFor='cost'>المجموع الكلي للجلسات</label>
                    </div>
                    <div className='field-container'>
                        <input className={data.totalDiscount === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='totalDiscount' value={data.totalDiscount} onChange={(e) => {
                            setData(d => {return {...d, totalDiscount:totalPrice >= e.target.value ? e.target.value : totalPrice}})
                            if(totalPrice >= e.target.value){
                                const arr = [...Array(sessionServices.length)]
                                const updatedArr = arr.map((_d,i) => {
                                    return sessionServices[i].price * sessionServices[i].sessions
                                })
                                setSlices(updatedArr)
                            }
                            changeTotalDiscount()
                            }}  />
                        <label className={data.totalDiscount === '' ? '' : 'active'} htmlFor='cost'>الخصم الكلي</label>
                    </div>
                    <div className='field-container disabled'>
                        <input disabled className={data.totalDiscount === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='totalDiscount' value={totalPrice - data.totalDiscount}  />
                        <label className={data.totalDiscount === '' ? '' : 'active'} htmlFor='cost'>المتبقي</label>
                    </div>
                </div> */}
                <div className='row'>
                    <button onClick={(e) => {
                        e.preventDefault()
                        if(!areSessionBeingAdded){
                            createSession()
                        }
                        
                    }}>{areSessionBeingAdded ? "تحميل..." : "إضافة"}</button>
                </div>
                </>
                    ) : ""
                }
            </form>
            <p style={{fontSize:"var(--paragraph)", color:"var(--primary)", textAlign:"center", marginTop:"1rem"}}>{msg}</p>
        </div>
    </div>
  )
}

export default AddSession