import { url } from "../URL";

const print = (img) => {
    const page = window.open("", "_blank");
    page.document.write(`
        <style>
            @media print {
                @page {
                    size: A0; /* Define the page size */
                    margin: 0; /* Remove default margins */
                }
                body, html {
                    width: 210mm;
                    height: 297mm;
                    margin: 0;
                    padding: 0;
                }
                img {
                    width: 100%;
                    height: 100%; /* Maintain aspect ratio */
                    display: block;
                }
            }
        </style>
        <img src="${url}${img}" alt="receipt" />
        <script>
            window.onload = function() {
                window.print();
                window.close();
            };
        </script>
    `);
    page.document.close();
};

export default print;
