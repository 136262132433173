import React from 'react'
import '../styles/Receipt.css'
import html2canvas from 'html2canvas';
import ReceiptPaper from './ReceiptPaper'
import print from '../Components/print'

const downloadReceipt = async (url) => {
    const element = document.getElementById("receiptsPaper"); // Replace with your div's ID
    const canvas = await html2canvas(element);
    const dataUrl = canvas.toDataURL("image/png"); // Capture the div as a PNG image
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "receipt.png"; 
    link.click();
}


function RecieptAlert(props) {
  return (
    <div id='Receipt' className={props.active ? 'active' : ""}>
        <div className='image' id='receiptPaperIMG'>
            {/* {
                props.url ? (
                    <img src={props.image} alt='reciept' loading='lazy' />
                ) : <div style={{display:'flex', alignItems:"center", justifyContent:"center", height:"100%", backgroundColor:"#228A8322"}}>
                    <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="var(--primary)"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                    </div>
            } */}
            <ReceiptPaper sessionData={props.data} />
        </div>
        <div className='btns'>
            <button onClick={(e) => {
                e.preventDefault()
                // download(props.url)
                downloadReceipt()
            }}>تحميل</button>
            <button onClick={() => {
                print(props.data.image)
            }}>طباعة</button>
            <button>أرسال للمراجع</button>
            {
                props.hasAfterNotes ? (
                    <button onClick={e => {
                        e.preventDefault()
                        props.addNotes()
                    }}>أضافة ملاحظات</button>
                ): ""
            }
        </div>
    </div>
  )
}

export default RecieptAlert