import React, { useState, useEffect } from 'react'
import { BsCheck, BsSearch } from 'react-icons/bs'
import '../styles/formPage.css'
import axios from 'axios'
import '../styles/Sessions.css'
import { url } from '../URL'
import getTime from './getTime'
import formatDate from './formatDate'
import UnAuthorized from './UnAuthorized'
import LaserPrompt from './LaserPrompt'
import EditSession from './EditSession'
import Alert from './Alert'
import AfterNote from './AfterNote'
import removeDuplicatesById from './removeDuplicateByID'
function PendingSessions() {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [msg, setMsg] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  function sortData(data){
    const sorted = data.sort((a, b) => {
      const dateA = new Date(`${a.start}`);
      const dateB = new Date(`${b.start}`);
      return dateA - dateB;
    })
    return sorted
  };

  async function loadData() {
    await axios.get(`${url}/sessions/?status=pending&page=${currentPage}`, {withCredentials:true})
      .then(res => {
        const d = res.data.data
        setData(prev => {
          return removeDuplicatesById( [...prev, ...d] )
        });
        setIsLoading(false)
      })
      .catch(err => {
        setMsg(err.response.data.message)
        console.log(err)
        if(err.response.status === 401){
          setUnAuthorized(true)
        }
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isLoading) {
      loadData()
    }
  }, [isLoading])

  const [unAuthorized, setUnAuthorized] = useState(false)
  const [isAlertActive, setIsAlertActive] = useState(false)
  const [isLaserActive, setIsLaserActive] = useState(false)
  const [isEditActive, setIsEditActive] = useState(false)
  const [laserData, setLaserData] = useState({
    wavelength:"",
    lensNumber:0,
    machineName:'',
    pulseDuration:0,
    count:0,
    fluenceNumber:0
})
const [newDate, setNewDate] = useState({
  start:"",
  startHour:""
})

const [isDeleteActive, setIsDeleteActive] = useState(false)

async function submit(data){
  setIsLaserActive(false)
  setIsEditActive(false)
  setIsDeleteActive(false)
  await axios.put(`${url}/sessions/get-done`, {data}, {withCredentials:true})
            .then(res => {
              setMsg(res.data.message)
              if(res.status === 200 || res.status === 201){
                window.location.reload()
              }
            })
            .catch(err => {
              console.log(err)
              setMsg(err.response.data.message)
            })
}
  async function finishSession(data){
    const checkLaser = data.category.includes('ليزر')
    if(!checkLaser){
      await axios.put(`${url}/sessions/get-done`, {data}, {withCredentials:true})
                .then(res => {
                  setMsg(res.data.message)
                  if(res.status === 200 || res.status === 201){
                    window.location.reload()
                  }
                })
                .catch(err => {
                  console.log(err)
                  setMsg(err.response.data.message)
                })
    }else {
      setIsLaserActive(true)
    }
  }
 
  async function handleChange(key, val){
    setLaserData(d => {
      return {...d, [key]: val}
    })
  }

  async function modifySessionDate(data,id){
    await axios.put(`${url}/sessions/${id}/change-date`, {data:{...data, paid:parseInt(data.paid)}}, {withCredentials:true})
              .then(res => {
                if(res.status === 201){
                  setMsg(res.data.message)
                  setIsEditActive(false)
                }
              })
              .catch(err => {
                console.log(err)
              })
  }

  async function deleteSession(id){
    await axios.delete(`${url}/sessions/${id}/delete`, {withCredentials:true})
              .then(res => {
                if(res.status === 200){
                  setMsg(res.data.message)
                  setIsDeleteActive(false)
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000);
                }
              })
              .catch(err => {
                console.log(err)
    })
  }
  const [patientID,setPatientID] = useState('')
  const [currentSession, setCurrentSession] = useState({})
  const [showNotes, setShowNotes] = useState(true)
  const [afterNotesActivity, setAfterNotesActivity] = useState(false)

  
    const [isSearching, setIsSearching] = useState(false)
    const handleScroll = () => {
      if(!isSearching){
        const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
        if (isAtBottom) {
          setIsLoading(true)
          setCurrentPage(prev => {
            return prev + 1
          })
        }
      }else  {
        setIsLoading(false)
        setCurrentPage(1)
      }
    };
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [currentPage, isSearching]);
  
  
    function checkIfUserIsSearching(){
      if(search || from || to){
        setIsSearching(true)
      }else {
        setIsSearching(false)
      }
    }
  
    useEffect(() => {
      checkIfUserIsSearching()
    }, [search, from,to]) 
  
  
    async function sendSearchRequest(){
      await axios.get(`${url}/sessions/search`, {withCredentials:true, params: {search, from ,to, status:"pending"}})
                .then(res => {
                  setData(res.data.data)
                })
                .catch(err => {
                  console.log(err)
                  alert("حدث خطأ")
                })
    }  
    useEffect(() => {
      if(!isLoading){
        sendSearchRequest()
      }
    }, [from,to]) 
  
  return unAuthorized ? <UnAuthorized /> : (
    <main className='page-container patients-page form-page'>
      <LaserPrompt isActive={isLaserActive} laserData={laserData} handleChange={handleChange} submit={() => {
        submit({...currentSession, ...laserData})
      }} />
      <AfterNote cancel={() => {
        setAfterNotesActivity(false)
      }} isActive={afterNotesActivity} sessionID={patientID} />
      <EditSession apply={() => {
        modifySessionDate(newDate, newDate._id)
      }} changeDate={(key, val) => {
        setNewDate(d => {
          return {...d, [key]: val}
        })
      }} session={newDate} isActive={isEditActive} cancel={() => {
        setIsEditActive(false)
      }} />
      <Alert 
      isActive={true} 
      classText={isDeleteActive ? 'alert active' : 'alert'} 
      dont={() => {setIsDeleteActive(false)}} 
      do={() => {
        deleteSession(currentSession._id)
      }}
      />
      <div className={isAlertActive || isLaserActive || isEditActive || isDeleteActive ? 'shadow active' : 'shadow'} onClick={() => {
        setIsAlertActive(false)
        setIsLaserActive(false)
        setIsEditActive(false)
        setIsDeleteActive(false)
        }}></div>
      <div className='container'>
        <section className='head-section' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <h1 className='title'>جلسات قيد الانتظار</h1>
          </div>
          <div>
            <h2 style={{ color: "var(--primary)" }}>العدد: {data.length}</h2>
          </div>
        </section>
        <section className='filter patients' style={{gridTemplateColumns:".6fr .2fr .2fr"}}>
          <div className='field-container'>
            <label>البحث</label>
            <div className='search-bar-grid'>
              <div className='icon' onClick={() => {
                sendSearchRequest()
              }}>
                <BsSearch />
              </div>
              <div className='search-bar'>
                <input type='text' placeholder='بحث...' className='field' value={search} onChange={e => { setSearch(e.target.value) }} />
              </div>
            </div>
          </div>
          <div className='field-container'>
            <label>من</label>
            <input type='date' style={{borderRadius:"7px"}} className='field' value={from} onChange={e => { setFrom(e.target.value) }} />
          </div>
          <div className='field-container'>
            <label>ألى</label>
            <input type='date' style={{borderRadius:"7px"}} className='field' value={to} onChange={e => { setTo(e.target.value) }} />
          </div>
        </section>
        <section className='filter patients'>
          <div className='field-container'>
            <label>خيارات</label>
            <div className='checkbox-container'>
              <div className={showNotes ? 'checkbox active' : 'checkbox'} onClick={() => {
                setShowNotes(!showNotes)
              }}>
                {
                  showNotes ? <BsCheck /> : ''
                }
              </div>
              <div>
                اظهار الملاحظات
              </div>
            </div>
          </div>
        </section>
        <section className='patients'>
          <div className='table-container'>
            {
              data.length > 0 ? (
                <table>
                  <thead className='sticky-thead'>
                    <tr>
                      <th>
                        أسم المراجع
                      </th>
                      <th>
                        الخدمة
                      </th>
                      <th>
                        التاريخ
                      </th>
                      <th>
                        الوقت
                      </th>
                      <th>
                        عدد الجلسات
                      </th>
                      <th>
                        المسؤول عن الجلسة
                      </th>
                      <th>
                        السعر الأجمالي
                      </th>
                      <th>
                        الخصم
                      </th>
                      <th>
                        المدفوع
                      </th>
                      {
                        showNotes ? (
                          <th>
                          ملاحظات
                        </th>
                        ) : ""
                      }
                      <th>
                        خيارات
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sortData(data.sort((a, b) => {
                        // Convert start date and startHour to a full Date object for comparison
                        const dateTimeA = new Date(`${a.start.split('T')[0]} ${a.startHour}`);
                        const dateTimeB = new Date(`${b.start.split('T')[0]} ${b.startHour}`);
                    
                        // Sort based on the combined date and time
                        return dateTimeA - dateTimeB;
                    })).map((d, i) => {
                        return (
                            <tr key={i}>
                                <td>{d.patient}</td>
                                <td>{d.category}-{d.service}</td>
                                <td>{formatDate(d.start)}</td>
                                <td>{getTime(d.startHour)}</td>
                                <td>{d.number}</td>
                                <td>{d.responsible}</td>
                                <td>{d.cost * d.number}</td>
                                <td>{d.discount}</td>
                                <td>{d.paid}</td>
                                {
                                    showNotes ? <td>{d.notes || "لا يوجد"}</td> : ""
                                }
                                <td style={{ display: 'grid', gridTemplateColumns: "repeat(3, 1fr)", gap: ".5rem" }}>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        setIsDeleteActive(true);
                                        setCurrentSession(d);
                                    }}>
                                        إلغاء الجلسة
                                    </button>
                                    <button style={{ color: "#f2f2f2" }} onClick={(e) => {
                                        e.preventDefault();
                                        setIsEditActive(true);
                                        setNewDate(d);
                                        setCurrentSession(d);
                                    }}>
                                        تعديل
                                    </button>
                                    <button style={{ color: "#f2f2f2", backgroundColor: "var(--primary) !important" }} onClick={(e) => {
                                        e.preventDefault();
                                        finishSession(d);
                                        setCurrentSession(d);
                                        setPatientID(d.patientID);
                                    }}>
                                        أنجاز
                                    </button>
                                </td>
                            </tr>
                        );
                    })                    
                    }
                  </tbody>
                </table>
              ) : (
                <div className='not-found'>
                  <div className='image'>
                    <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
                  </div>
                  <div>
                    <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
                  </div>
                </div>
              )
            }
          </div>
          <p>{msg}</p>
        </section>
      </div>
    </main>
  )
}

export default PendingSessions
